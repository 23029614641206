import React, { useEffect } from 'react';

import cls from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className={cls.main}>
            <h1>Политика конфиденциальности</h1>
            <div>
                <p>
                    Мы высоко ценим ваш интерес к нашей компании и предоставляемым услугам. Защита персональных данных
                    для
                    нас очень важна. Мы соблюдаем правила защиты персональных данных и защиты ваших данных от
                    несанкционированного доступа третьих лиц (защита персональных данных) в рамках Законодательства
                    Кыргызской Республики.
                    Заполнение формы с контактными данными означает безоговорочное согласие с настоящей Политикой
                    конфиденциальности и указанными в ней условиями обработки персональной информации.
                    Ниже приводится информация об обработке персональных данных.
                    <ol>
                        <span>1</span> Персональные данные. Цель сбора и обработки персональных данных.
                    </ol>
                </p>
                <p>
                    1.1. Вы всегда можете посетить данную страницу, не раскрывая никакой персональной информации.

                </p>
                <p>
                    1.2. Под персональными данными понимается любая информация, относящаяся к определенному или
                    определяемому на основании такой информации физическому лицу.
                </p>
                <p>
                    1.3. Наша Компания собирает и использует персональные данные, необходимые для выполнения Вашего
                    запроса,
                    это – фамилия, имя, телефон и электронный адрес.
                </p>
                <p>
                    1.4. ОсОО «Практикум» не проверяет достоверность персональных данных, предоставляемых физическими
                    лицами, и не проверяет их дееспособность.
                    <ol>
                        <span>2</span> Условия обработки персональной информации слушателя и её передачи третьим лицам.

                    </ol>
                </p>
                <p>
                    2.1. При обработке персональных данных посетителей сайта Компания руководствуется Законодательством
                    Кыргызской Республики «О персональных данных».
                </p>
                <p>
                    2.2. Собираемые персональные данные позволяют направлять Слушателям уведомления о новых предложениях
                    и
                    различных активностях, организуемых Компанией, а также для подтверждения и согласования участия в
                    них
                    Слушателя. Компания может использовать персональные данные Слушателя для отправки важных
                    уведомлений,
                    содержащих информацию об изменениях нормативных документов Компании. Персональные данные также могут
                    быть использованы для улучшения предоставляемых продуктов, услуг, качества контента и коммуникаций.
                </p>
                <p>
                    2.3. Персональные данные Слушателя хранятся исключительно на электронных носителях и обрабатываются
                    без
                    ограничения срока, любым законным способом, в том числе в информационных системах персональных
                    данных с
                    использованием средств автоматизации или без использования таких средств.
                </p>
                <p>
                    2.4. Если Слушатель не желает быть включенным в список рассылки Компании, он может в любой момент
                    отписаться от рассылки путём информирования Компании по контактам для обратной связи.
                </p>
                <p>
                    2.5. В отношении персональной информации Слушателя сохраняется ее конфиденциальность.
                </p>
                <p>
                    2.6. ОсОО «Практикум» не передает персональные данные третьим лицам, за исключением случаев, когда
                    данные Пользователя могут быть переданы по запросам уполномоченных органов государственной власти
                    только
                    по основаниям и в порядке, установленным Кыргызской Республики.
                    <ol>
                        <span>3</span> Меры, применяемые для защиты персональной информации пользователей.
                    </ol>
                    Компания принимает необходимые и достаточные организационные и технические меры для защиты
                    персональной
                    информации пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                    блокирования,
                    копирования, распространения, а также от иных неправомерных действий ней третьих лиц.
                    ОсОО «Практикум» оставляет за собой право вносить любые необходимые изменения в Политику защиты
                    конфиденциальной информации.
                    Если у вас есть какие-либо вопросы или опасения по поводу этой политики конфиденциальности,
                    перейдите на
                    страницу «Свяжитесь с нами».
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;